import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container, Row, Col, Table, Button, Modal, Form, Spinner, Alert
} from 'react-bootstrap';
import { FaEdit, FaEye, FaEyeDropper, FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import ENV from "../utils/Constant";
import AuthProvider from "../utils/AuthProvider";
import { ArrowLeftCircle } from 'react-bootstrap-icons'; // Import the desired icon

const CadreList = () => {
    const apiUrl = ENV.baseUrl;
    const [cadres, setCadres] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [selectedCadre, setSelectedCadre] = useState({});
    const [newCadre, setNewCadre] = useState({
        cadreName: '',
        totalCadreSanctionedStrength: '',
        totalCadreManInPosition: ''
    });



    const navigate = useNavigate();
    // Inline styles for the button
    const buttonStyle = {
        backgroundColor: '#007bff', // Blue background
        color: '#fff', // White text
        border: 'none', // Remove border
        padding: '10px 20px', // Padding
        borderRadius: '5px', // Rounded corners
        cursor: 'pointer', // Pointer cursor on hover
        display: 'flex', // Flexbox for alignment
        alignItems: 'center', // Center items vertically
        fontSize: '16px', // Font size
        marginBottom: '20px' // Space between button and table
    };

    // Inline styles for the icon
    const iconStyle = {
        marginRight: '8px', // Space between icon and text
    };

    const handleBackClick = () => {
        navigate(-1); // Navigate back in history
    };

    useEffect(() => {
        const fetchCadres = async () => {
            setLoading(true);
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider
                const response = await axios.get(`${apiUrl}/cadres`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });
                console.log("response", response);

                if (Array.isArray(response.data)) {
                    setCadres(response.data);
                } else {
                    console.error('Unexpected response format:', response.data);
                    setError('Unexpected response format.');
                }
                setLoading(false);
                setError(null);
            } catch (error) {
                console.error('Error fetching cadres:', error);
                setError('Error fetching cadres. Please try again.');
                setLoading(false);
            }
        };

        fetchCadres();
    }, [apiUrl]);


    const handleEditOpen = (cadre) => {
        setSelectedCadre(cadre);
        setShowEditModal(true);
    };

    const handleEditClose = () => {
        setShowEditModal(false);
    };

    const handleNewOpen = () => {
        setShowNewModal(true);
    };

    const handleNewClose = () => {
        setShowNewModal(false);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setSelectedCadre({ ...selectedCadre, [name]: value });
    };

    const handleNewChange = (e) => {
        const { name, value } = e.target;
        setNewCadre({ ...newCadre, [name]: value });
    };

    const handleEditSubmit = async () => {
        if (validateCadre(selectedCadre)) {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

                await axios.put(`${apiUrl}/cadres/edit/${selectedCadre.cadreId}`, selectedCadre, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                setCadres(cadres.map(cadre => cadre.cadreId === selectedCadre.cadreId ? selectedCadre : cadre));
                handleEditClose();
                showToast('Cadre updated successfully', 'success');
            } catch (error) {
                console.error('Error updating cadre:', error);
                showToast('Error updating cadre. Please try again.', 'error');
            }
        } else {
            showToast('Man in Position should be less than or equal to Sanctioned Strength', 'error');
        }
    };

    const handleNewSubmit = async () => {
        if (validateCadre(newCadre)) {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider


                const response = await axios.post(`${apiUrl}/cadres/create-cadre`, newCadre, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                setCadres([...cadres, response.data]);
                handleNewClose();
                showToast('New cadre created successfully', 'success');
                setNewCadre({
                    cadreName: '',
                    totalCadreSanctionedStrength: '',
                    totalCadreManInPosition: ''
                }); // Clear the form
            } catch (error) {
                console.error('Error creating new cadre:', error);
                showToast('Error creating new cadre. Please try again.', 'error');
            }
        } else {
            showToast('Man in Position should be less than or equal to Sanctioned Strength', 'error');
        }
    };


    const handleViewDesignations = (cadreId) => {
        navigate(`/designations/${cadreId}`);
    };

    const validateCadre = (cadre) => {
        return parseInt(cadre.totalCadreManInPosition, 10) <= parseInt(cadre.totalCadreSanctionedStrength, 10);
    };

    const showToast = (message, type) => {
        toast(message, { type });
    };

    const Userroles = localStorage.getItem('role')

    return (
        < div className="md:p-4">
            <div className='flex flex-row w-auto pt-2 items-center'>
                <button onClick={handleBackClick} style={buttonStyle}>
                    <ArrowLeftCircle style={iconStyle} /> Go Back
                </button>
                {Userroles === 'ROLE_ADMIN' && (
                    <div className='lg:p-4 p-2'>
                        <button style={buttonStyle} onClick={handleNewOpen}>
                            <div className='flex items-center gap-2 '>
                                New Cadre
                                <FaPlus />
                            </div>
                        </button>
                    </div>
                )}
            </div>
            {loading ? (
                <Spinner animation="border" />
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : (
                <div style={{ overflowX: 'auto' }}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Cadre Name</th>
                                <th>Sanctioned Strength</th>
                                <th className=''>Man in Position</th>
                                <th className=''>Total Vacancy</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(cadres) && cadres.length > 0 ? (
                                cadres.map((cadre) => (
                                    <tr key={cadre.cadreId}>
                                        <td>{cadre.cadreName}</td>
                                        <td>{cadre.totalCadreSanctionedStrength}</td>
                                        <td className=''>{cadre.totalCadreManInPosition}</td>
                                        <td className=''>{cadre.totalCadreSanctionedStrength - cadre.totalCadreManInPosition}</td>
                                        <td className='flex  flex-col lg:flex-row gap-2'>
                                            <Button variant="secondary"
                                                onClick={() => handleViewDesignations(cadre.cadreId)}>
                                                <div className='flex items-center justify-center'>
                                                    <FaEye />
                                                </div>
                                            </Button> {''}
                                            {Userroles === 'ROLE_ADMIN' && (
                                                <Button variant="warning" onClick={() => handleEditOpen(cadre)}>
                                                    <div className='flex items-center justify-center'>
                                                        <FaEdit />
                                                    </div>
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">No cadres available</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            )}

            {/* Edit Modal */}
            <Modal show={showEditModal} onHide={handleEditClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Cadre</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formCadreName">
                            <Form.Label>Cadre Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="cadreName"
                                value={selectedCadre.cadreName}
                                onChange={handleEditChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formSanctionedStrength">
                            <Form.Label>Sanctioned Strength</Form.Label>
                            <Form.Control
                                type="number"
                                name="totalCadreSanctionedStrength"
                                value={selectedCadre.totalCadreSanctionedStrength}
                                onChange={handleEditChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formManInPosition">
                            <Form.Label>Man in Position</Form.Label>
                            <Form.Control
                                type="number"
                                name="totalCadreManInPosition"
                                value={selectedCadre.totalCadreManInPosition}
                                onChange={handleEditChange}
                            />
                        </Form.Group>
                        {parseInt(selectedCadre.totalCadreManInPosition, 10) > parseInt(selectedCadre.totalCadreSanctionedStrength, 10) && (
                            <Alert variant="danger">
                                Man in Position should be less than or equal to Sanctioned Strength
                            </Alert>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleEditClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="success"
                        onClick={handleEditSubmit}
                        disabled={parseInt(selectedCadre.totalCadreManInPosition, 10) > parseInt(selectedCadre.totalCadreSanctionedStrength, 10)}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* New Cadre Modal */}
            <Modal show={showNewModal} onHide={handleNewClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Cadre</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formNewCadreName">
                            <Form.Label>Cadre Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="cadreName"
                                value={newCadre.cadreName}
                                onChange={handleNewChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formNewSanctionedStrength">
                            <Form.Label>Sanctioned Strength</Form.Label>
                            <Form.Control
                                type="number"
                                name="totalCadreSanctionedStrength"
                                value={newCadre.totalCadreSanctionedStrength}
                                onChange={handleNewChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formNewManInPosition">
                            <Form.Label>Man in Position</Form.Label>
                            <Form.Control
                                type="number"
                                name="totalCadreManInPosition"
                                value={newCadre.totalCadreManInPosition}
                                onChange={handleNewChange}
                            />
                        </Form.Group>
                        {parseInt(newCadre.totalCadreManInPosition, 10) > parseInt(newCadre.totalCadreSanctionedStrength, 10) && (
                            <Alert variant="danger">
                                Man in Position should be less than or equal to Sanctioned Strength
                            </Alert>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleNewClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="success"
                        onClick={handleNewSubmit}
                        disabled={parseInt(newCadre.totalCadreManInPosition, 10) > parseInt(newCadre.totalCadreSanctionedStrength, 10)}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CadreList;
