// Sidebar.js
import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faLayerGroup, faColumns, faUsers, faUser } from '@fortawesome/free-solid-svg-icons';
import { GiHamburgerMenu } from "react-icons/gi";
import AuthProvider from '../utils/AuthProvider';
import { FaSignOutAlt } from 'react-icons/fa';
import { Si4Chan } from "react-icons/si";
import { FaCalculator } from "react-icons/fa";

const Sidebar = ({ show, handleClose, setIsAuthenticated }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const isActive = (path) => location.pathname === path;

    const handleLogout = () => {
        AuthProvider.logout();
        setIsAuthenticated(false);
        localStorage.setItem("isAuthenticated", false);
        navigate('/login'); // Redirect to the login page
    };
    const Userroles = localStorage.getItem('role')

    return (
        <div
            className={`bg-white lg:h-screen lg:sticky fixed lg:border-r-0 border-r-2 shadow-2xl lg:shadow-none border-black z-50 h-full left-0 top-0 w-80 lg:w-72 transition-transform lg:translate-x-0 ${show ? 'translate-x-0' : '-translate-x-full'
                } flex flex-col justify-between`}
        >
            <div>
                <div className="p-2 sidebar-title lg:h-20 w-full flex justify-between items-center">
                    <div className='flex items-center gap-2 justify-center w-full px-4'>
                        <span className='rounded-md items-center'>
                            <img src="/logo192.png" className='w-16 h-12' />
                        </span>
                        <h3 className=" w-full font-bold text-2xl">JoRDiRAS</h3>
                    </div>
                    {show && (
                        <div className="">
                            <button onClick={handleClose} className=" p-2">
                                <GiHamburgerMenu className="text-black size-6" />
                            </button>
                        </div>
                    )}
                </div>
                <div className="flex flex-col text-black">
                    {/* <div className='flex items-center justify-center w-full px-4'>
                        <h3 className=" w-full font-bold text-2xl">Welcome</h3>
                    </div> */}
                    <div className="flex flex-col text-black">
                        <div
                            className={`py-2 pr-4 flex gap-2 group ${isActive('/dashboard') ? ' border-success rounded-xl' : 'border-white'
                                }`}
                        >
                            <span className={`lg:border-r-8  ${isActive('/dashboard') ? ' border-success rounded-r-lg' : 'border-white'
                                }`}>

                            </span>
                            <div className={`${isActive('/dashboard') ? 'text-white bg-[#198754]' : ' '} group-hover:text-white  group-hover:bg-[#198754] rounded-md  w-full p-2`}>
                                <Nav.Link as={Link} to="/dashboard" onClick={handleClose}>
                                    <div className="flex items-center w-full gap-3 ">
                                        <FontAwesomeIcon icon={faTachometerAlt} className="mb-1" />
                                        <h2 className={`text-lg `}>Dashboard</h2>
                                    </div>
                                </Nav.Link>
                            </div>
                        </div>

                        <div
                            className={`py-2 pr-4 flex gap-2 group ${isActive('/reservation-calculations') ? ' border-success rounded-xl' : 'border-white'
                                }`}
                        >
                            <span className={`lg:border-r-8  ${isActive('/reservation-calculations') ? ' border-success rounded-r-lg' : 'border-white'
                                }`}>

                            </span>
                            <div className={`${isActive('/reservation-calculations') ? 'text-white bg-[#198754]' : ' '} group-hover:text-white  group-hover:bg-[#198754] rounded-md  w-full p-2`}>
                                <Nav.Link as={Link} to="/reservation-calculations" onClick={handleClose}>
                                    <div className="flex items-center w-full gap-3 ">
                                        <FaCalculator className="mb-1" />
                                        <h2 className={`text-lg `}>
                                            Reservation calculator
                                        </h2>
                                    </div>
                                </Nav.Link>
                            </div>
                        </div>

                        <div
                            className={`py-2 pr-4 flex gap-2 group ${isActive('/vertical-reservation') ? ' border-success rounded-xl' : 'border-white'
                                }`}
                        >
                            <span className={`lg:border-r-8  ${isActive('/vertical-reservation') ? ' border-success rounded-r-lg' : 'border-white'
                                }`}>

                            </span>
                            <div className={`${isActive('/vertical-reservation') ? 'text-white bg-[#198754]' : ' '} group-hover:text-white  group-hover:bg-[#198754] rounded-md  w-full p-2`}>
                                <Nav.Link as={Link} to="/vertical-reservation" onClick={handleClose}>
                                    <div className="flex items-center w-full gap-3 ">
                                        <FontAwesomeIcon icon={faLayerGroup} className="mb-1" />
                                        <h2 className={`text-lg `}>
                                            Vertical Reservation
                                        </h2>
                                    </div>
                                </Nav.Link>
                            </div>
                        </div>

                        <div
                            className={`py-2 pr-4 flex gap-2 group ${isActive('/horizontal-reservation') ? ' border-success rounded-xl' : 'border-white'
                                }`}
                        >
                            <span className={`lg:border-r-8  ${isActive('/horizontal-reservation') ? ' border-success rounded-r-lg' : 'border-white'
                                }`}>

                            </span>
                            <div className={`${isActive('/horizontal-reservation') ? 'text-white bg-[#198754]' : ' '} group-hover:text-white  group-hover:bg-[#198754] rounded-md  w-full p-2`}>
                                <Nav.Link as={Link} to="/horizontal-reservation" onClick={handleClose}>
                                    <div className="flex items-center w-full gap-3 ">
                                        <FontAwesomeIcon icon={faColumns} className="mb-1" />
                                        <h2 className={`text-lg `}>
                                            Horizontal Reservation
                                        </h2>
                                    </div>
                                </Nav.Link>
                            </div>
                        </div>


                        {/* <div
                            className={`py-2 pr-4 flex gap-2 ${isActive('/cadre') ? ' border-success rounded-xl' : 'border-white'
                                }`}
                        >
                            <span className={`lg:border-r-8  ${isActive('/cadre') ? ' border-success rounded-r-lg' : 'border-white'
                                }`}>

                            </span>
                            <Nav.Link as={Link} to="/cadre" className="text-black" onClick={handleClose}>
                                <div className="flex items-center w-full gap-2">
                                    <FontAwesomeIcon icon={faUsers} className="sidebar-icon" style={{ color: '#198754' }} />
                                    <h2 className={`text-lg ${isActive('/cadre') ? ' text-success' : 'text-black'}`}>Cadre</h2>
                                </div>
                            </Nav.Link>
                        </div> */}
                        {Userroles === 'ROLE_ADMIN' && (
                            <div
                                className={`py-2 pr-4 flex gap-2 group ${isActive('/user') ? ' border-success rounded-xl' : 'border-white'
                                    }`}
                            >
                                <span className={`lg:border-r-8  ${isActive('/user') ? ' border-success rounded-r-lg' : 'border-white'
                                    }`}>

                                </span>
                                <div className={`${isActive('/user') ? 'text-white bg-[#198754]' : ' '} group-hover:text-white  group-hover:bg-[#198754] rounded-md  w-full p-2`}>
                                    <Nav.Link as={Link} to="/user" onClick={handleClose}>
                                        <div className="flex items-center w-full gap-3 ">
                                            <FontAwesomeIcon icon={faUser} className="mb-1" />
                                            <h2 className={`text-lg `}>
                                                User
                                            </h2>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="w-full  px-3 pb-3">
                <button className=" w-full py-2 border-2 border-black rounded-2xl group-hover:border-[#198754]  hover:bg-[#198754] group duration-300 transition-all" onClick={handleLogout}>
                    <h2 className='font-bold text-lg  group-hover:text-white flex justify-center items-center gap-2'>
                        <FaSignOutAlt />Logout
                    </h2>
                </button>
            </div>
        </div>

    );
}

export default Sidebar;