import { useState, useEffect } from 'react';
import axios from 'axios';
import AuthProvider from "../utils/AuthProvider";
import ENV from "../utils/Constant";

const useCadreCountData = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

                const response = await axios.get(`${ENV.baseUrl}/cadres/count`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                console.log("response.data", response.data)

                setData(response.data.totalCadres);
                setLoading(false);
            } catch (err) {
                setError('Error fetching cadre count. Please try again.');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { data, loading, error };
};

export default useCadreCountData;
