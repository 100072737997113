import React from 'react';
import DashboardCard from "./DashboardCard";
import useVerticalReservationData from './useVerticalReservationData';
import useHorizontalReservationData from "./useHorizontalReservationData";
import useCadreCountData from "./useCadreData";
// import useRoleStore from '../utils/helpers/store/zustand';

const Dashboard = () => {
    const { data, loading, error } = useVerticalReservationData();
    const { data: horizontalData, loading: horizontalLoading, error: horizontalError } = useHorizontalReservationData();
    const { data: cadreCount, loading: cadreLoading, error: cadreError } = useCadreCountData();  // Use the new hook

    const verticalReservationDescription = loading ? "Loading..." : error ? error : `Reservation: ${data.totalPercentage}%, Unreserved: ${data.remainingPercentage}%`;
    const horizontalReservationDescription = horizontalLoading ? "Loading..." : horizontalError ? horizontalError : `Reservation: ${horizontalData.totalPercentage}%, Unreserved: ${horizontalData.remainingPercentage}%`;
    const cadreDescription = cadreLoading ? "Loading..." : cadreError ? cadreError : `Total Cadre Count: ${cadreCount}`;
    const reservationCalculationsDescription = cadreLoading ? "Loading..." : cadreError ? cadreError : `Total Cadre Count: ${cadreCount}`;

    // const roles = useRoleStore((state) => state.roles); // Access roles from the store
    const Userroles = localStorage.getItem('role')

    return (
        <div className="md:p-4">
            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
                {Userroles === 'ROLE_ADMIN' && (
                    <>
                        <DashboardCard
                            title="Vertical Reservation Distribution"
                            description={verticalReservationDescription}
                            route="/vertical-reservation"
                            type="verticalReservation"
                        />
                        <DashboardCard
                            title="Horizontal Reservation Distribution"
                            description={horizontalReservationDescription}
                            route="/horizontal-reservation"
                            type="horizontalReservation"
                        />
                        {/* <DashboardCard
                            title="Cadre Management"
                            description={cadreDescription}
                            route="/cadre"
                            type="cadre"
                        /> */}
                    </>
                )}
                <DashboardCard
                    title="Reservation Calculations"
                    description={reservationCalculationsDescription}
                    route="/reservation-calculations"
                    type="reservationCalculations"
                />
            </div>
        </div>
    );
};

export default Dashboard;
