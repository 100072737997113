import { create } from 'zustand';

// Define the Zustand store
const useRoleStore = create((set) => ({
    roles: [], // Initial state

    // Action to set roles
    setRoles: (roles) => set({ roles }),

    // Action to clear roles
    clearRoles: () => set({ roles: [] }),
}));

export default useRoleStore;
