import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faColumns, faTachometerAlt, faLayerGroup, faUsers, faTag, faExclamationCircle, faCogs } from '@fortawesome/free-solid-svg-icons';

const cardStyles = {
    verticalReservation: {
        background: '#fff',
        color: '#030202',
    },
    horizontalReservation: {
        background: '#fff',
        color: '#030202',
    },
    cadre: {
        background: '#fff',
        color: '#030202',
    },
    designation: {
        background: '#fff',
        color: '#000',
    },
    reservationCalculations: {
        background: '#fff',
        color: '#030202',
    },
    vacancy: { backgroundColor: '#17a2b8', color: '#fff' },
    seatAllocation: { backgroundColor: '#6c757d', color: '#fff' },
};

const iconMap = {
    verticalReservation: faLayerGroup,
    horizontalReservation: faColumns,
    cadre: faUsers,
    designation: faTag,
    vacancy: faExclamationCircle,
    seatAllocation: faCogs,
};

const DashboardCard = ({ title, description, route, type }) => {
    const style = cardStyles[type] || cardStyles['default'];
    const icon = iconMap[type] || faChartBar;

    return (
        <div className="w-full p-4 rounded-md shadow-md" style={style}>
            <Card.Body className="flex flex-col  justify-between gap-4">
                <div className="flex flex-col  items-center mb-3 gap-4">
                    <FontAwesomeIcon icon={icon} size="3x" className="me-3" />
                    <div className='text-center'>
                        <div>{title}</div>
                        <div className='text-lg'>{description}</div >
                    </div>
                </div>
                <div className="flex items-center py-2 justify-center w-full btn btn-success">
                    <a href={route} className=" no-underline  text-center text-white w-full font-bold">View Details</a>
                </div>
            </Card.Body>
        </div>
    );
};

export default DashboardCard;
