import React from 'react';
import { Navbar, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import AuthProvider from '../utils/AuthProvider'; // Ensure the correct path to AuthProvider

const Header = ({ setIsAuthenticated }) => {
    // const navigate = useNavigate();

    // const handleLogout = () => {
    //     AuthProvider.logout();
    //     setIsAuthenticated(false);
    //     localStorage.setItem("isAuthenticated", false);
    //     navigate('/login'); // Redirect to the login page
    // };

    return (
        <div className="lg:py-4 py-2 text-black lg:px-8 lg:h-20 flex w-full ">
            <div className='flex flex-col lg:flex-row lg:gap-4 gap-2 justify-between w-full items-center'>
                <a href="/" className='no-underline text-lg text-gray-400 font-semibold '>
                    Job Reservation Digital Roster Analysis System
                </a>
                {/*<Button variant="outline-light" className='lg:w-auto flex justify-end' onClick={handleLogout}>Logout</Button>*/}
            </div>
        </div>
    );
};

export default Header;
