import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import axios from "axios";
import Logo from "../../assets/logo192.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from '../../utils/AuthProvider';
import LOGIN_ENV from "../../utils/LoginConstant";
import './Login.css'; // Import the CSS file
import useRoleStore from "../../utils/helpers/store/zustand";

const Login = ({ setIsAuthenticated }) => {
    const apiUrl = LOGIN_ENV.baseUrl;
    const [inputUsername, setInputUsername] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const setRoles = useRoleStore((state) => state.setRoles);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setShow(false);

        try {
            const response = await axios.post(`${apiUrl}/login`, {
                username: inputUsername,
                password: inputPassword
            });
            // console.log('API Response:', response.data); // Debugging line
            setRoles(response.data.roles);
            localStorage.setItem("role", response.data.roles);

            // Handle successful login
            AuthProvider.handleLoginSuccess(response, setIsAuthenticated);
            toast.success('Login successful!');
            // Redirect to dashboard or handle login success as needed

        } catch (error) {
            setShow(true);
            if (error.response && error.response.status === 401) {
                setErrorMessage("Invalid credentials");
                toast.error('Invalid credentials');
            } else {
                setErrorMessage("An error occurred during authentication");
                toast.error('An error occurred during authentication');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="wrapper">
            <div className="backdrop"></div>
            <Card className="card">
                <Card.Body>
                    <div className="logo-container">
                        <img className="logo" src={Logo} alt="logo" />
                    </div>
                    <div className="main-header">Job Reservation Digital Roster Analysis System</div>
                    <div className="sub-header">Sign In</div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-2" controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                value={inputUsername}
                                placeholder="Username"
                                autoComplete="current-Username"
                                onChange={(e) => setInputUsername(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={inputPassword}
                                placeholder="Password"
                                autoComplete="current-password"
                                onChange={(e) => setInputPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="checkbox">
                            <Form.Check type="checkbox" label="Remember me" />
                        </Form.Group>
                        {!loading ? (
                            <Button className="button" variant="success" type="submit">
                                Log In
                            </Button>
                        ) : (
                            <Button className="button" variant="primary" type="submit" disabled>
                                Logging In...
                            </Button>
                        )}
                        <div className="footer-buttons">
                            <Button className="link-button" variant="link">
                                Forgot password?
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
            <div className="footer">Made by Hendrik C | &copy;2022</div>
            <ToastContainer />
        </div>
    );
};

export default Login;
