import React, { useEffect, useState } from 'react'
import ENV from '../utils/Constant';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import AuthProvider from '../utils/AuthProvider';
import { Button, Modal, Table, Form } from 'react-bootstrap';
import { ArrowLeftCircle } from 'react-bootstrap-icons';
import { toast, ToastContainer } from 'react-toastify';
import { FaEdit, FaTrash } from 'react-icons/fa';

const CarryForwardList = () => {
    const apiUrl = ENV.baseUrl;
    const { Id } = useParams();
    const navigate = useNavigate();
    const [data, setdata] = useState([])
    const [editDesignation, setEditDesignation] = useState(null);
    const [VerticalReservation, setVerticalReservation] = useState('');
    const [HorizontalReservation, setHorizontalReservation] = useState('');
    const [CFValue, setCFValue] = useState('');

    const [showEditModal, setShowEditModal] = useState(false);

    const buttonStyle = {
        backgroundColor: '#007bff', // Blue background
        color: '#fff', // White text
        border: 'none', // Remove border
        padding: '10px 20px', // Padding
        borderRadius: '5px', // Rounded corners
        cursor: 'pointer', // Pointer cursor on hover
        display: 'flex', // Flexbox for alignment
        alignItems: 'center', // Center items vertically
        fontSize: '16px', // Font size
        marginBottom: '20px' // Space between button and table
    };
    const iconStyle = {
        marginRight: '8px', // Space between icon and text
    };
    useEffect(() => {
        const fetchHorizontalReservations = async () => {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

                const response = await axios.get(`${apiUrl}/carry-forward/by-designationId/${Id}`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                // console.log(response.data);
                setdata(response.data);
            } catch (error) {
                console.error('Error fetching carry-forward:', error);
            }
        };

        fetchHorizontalReservations();
    }, [apiUrl]);
    const handleBackClick = () => {
        navigate(-1); // Navigate back in history
    };
    const handlePrint = () => {
        window.print();
    };
    const handleDeleteDesignation = () => {

    }

    const handleEditDesignation = async () => {
        if (!VerticalReservation || !HorizontalReservation || !CFValue) {
            toast.error('Please fill in all fields.');
            return;
        }

        try {
            // Update the editDesignation object with the new values
            const updatedDesignation = {
                ...editDesignation,
                VerticalReservation,
                HorizontalReservation,
                CFValue
            };

            // Uncomment and modify the API call as needed
            // const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider
            // await axios.put(`${apiUrl}/designations/edit/${editDesignation.designationId}`, updatedDesignation, {
            //     headers: {
            //         Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
            //     }
            // });

            setShowEditModal(false);
            setEditDesignation(null);
            toast.success('Designation updated successfully!');
        } catch (error) {
            console.error('Error editing designation:', error.response ? error.response.data : error.message);
            toast.error('Error editing designation. Please try again.');
        }
    };

    const openEditModal = (item) => {
        setEditDesignation(item.carryForwardId);
        setShowEditModal(true);
    }

    return (
        < div className="md:p-4">
            <div className='flex flex-col lg:flex-row w-auto pt-2 gap-2 items-center'>
                <button onClick={handleBackClick} style={buttonStyle}>
                    <ArrowLeftCircle style={iconStyle} /> Go Back
                </button>
                {data.length > 0 && (

                    <button onClick={handlePrint} style={buttonStyle}>
                        Print Table
                    </button>
                )}
            </div>
            {data.length > 0 ? (
                <>
                    <h4 style={{ marginBottom: '20px' }}>Designation: {data[0].designation.designationName || 'Loading...'}</h4>

                    <div style={{ overflowX: 'auto' }}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Sl No</th>
                                    <th>Vertical Reservation</th>
                                    <th>Horizontal Reservation</th>
                                    <th>CF Value</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={item.carryForwardId}>
                                        <td>{index + 1}</td>
                                        <td>{item.verticalReservation.categoryName}</td>
                                        <td>{item.horizontalReservation.categoryName}</td>
                                        <td>{item.cfValue}</td>
                                        <td className='flex gap-2 flex-col lg:flex-row'>
                                            <div className='flex lg:w-auto w-full gap-2 '>
                                                <Button
                                                    className='flex items-center justify-center w-full'
                                                    variant="warning"
                                                    onClick={() => openEditModal(item)}
                                                >
                                                    <div className='flex items-center justify-center'>
                                                        <FaEdit />
                                                    </div>
                                                </Button>
                                                <Button
                                                    className='flex items-center justify-center w-full'
                                                    variant="danger"
                                                    onClick={() => handleDeleteDesignation(item.carryForwardId)}
                                                >
                                                    <div className='flex items-center justify-center'>
                                                        <FaTrash />
                                                    </div>
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </>
            ) :
                <div className='flex flex-col lg:flex-row w-auto pt-2 items-center'>
                    <p>
                        No carry-forward data found for this designation. Please go back and try again.
                    </p>
                </div>
            }

            {/* Edit Carry Forward Modal */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Carry Forward</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editDesignation && (
                        <Form onSubmit={handleEditDesignation}>
                            <Form.Group className="mb-3" controlId="formEditVertical">
                                <Form.Label>Vertical Reservation</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Vertical Reservation"
                                    value={VerticalReservation}
                                    onChange={(e) => setVerticalReservation(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEditHorizontal">
                                <Form.Label>Horizontal Reservation</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Horizontal Reservation"
                                    value={HorizontalReservation}
                                    onChange={(e) => setHorizontalReservation(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEditCF">
                                <Form.Label>CF Value</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter CF Value"
                                    value={CFValue}
                                    onChange={(e) => setCFValue(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Close
                    </Button>
                    <Button variant="success" >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CarryForwardList