import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, Button, Modal, Form, Spinner, Alert
} from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { toast, ToastContainer as ReactToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import LOGIN_ENV from "../../utils/LoginConstant";
import AuthProvider from "../../utils/AuthProvider";

const UserList = () => {
    const apiUrl = LOGIN_ENV.baseUrl;
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [newUser, setNewUser] = useState({
        username: '',
        email: '',
        password: '',
        role: ''
    });
    const [roles, setRoles] = useState([]);
    const [currentUser, setCurrentUser] = useState(null); // Store current user info
    const [newFormSubmitted, setNewFormSubmitted] = useState(false); // Track form submission

    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();
        fetchRoles();
    }, [apiUrl]);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const token = AuthProvider.getToken();
            const response = await axios.get(`${apiUrl}/users`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });


            if (Array.isArray(response.data)) {
                setUsers(response.data);
            } else {
                console.error('Unexpected response format:', response.data);
                setError('Unexpected response format.');
            }
            setLoading(false);
            setError(null);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Error fetching users. Please try again.');
            setLoading(false);
        }
    };

    const fetchRoles = async () => {
        try {
            const token = AuthProvider.getToken();
            const response = await axios.get(`${apiUrl}/roles`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (Array.isArray(response.data)) {
                setRoles(response.data);
            } else {
                console.error('Unexpected response format for roles:', response.data);
            }
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const handleEditOpen = (user) => {
        setSelectedUser(user);
        setShowEditModal(true);
    };

    const handleEditClose = () => {
        setShowEditModal(false);
    };

    const handleNewOpen = () => {
        setNewUser({
            username: '',
            email: '',
            password: '',
            role: ''
        });
        setShowNewModal(true);
    };

    const handleNewClose = () => {
        setShowNewModal(false);
        setNewFormSubmitted(false); // Reset form submission flag
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setSelectedUser(prev => ({ ...prev, [name]: value }));
    };

    const handleNewChange = (e) => {
        const { name, value } = e.target;
        setNewUser(prev => ({ ...prev, [name]: value }));
    };

    const validateForm = (user) => {
        if (!user.username || !user.email || !user.password || !user.role) {
            return false;
        }
        return true;
    };

    const handleEditSubmit = async () => {
        if (!validateForm(selectedUser)) {
            showToast('Please fill all required fields.', 'error');
            return;
        }
        if (currentUser && currentUser.id === selectedUser.id) {
            showToast("You can't edit yourself.", 'error');
            return;
        }
        try {
            const token = AuthProvider.getToken();
            await axios.put(`${apiUrl}/edit/${selectedUser.id}`, selectedUser, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setUsers(users.map(user => user.id === selectedUser.id ? selectedUser : user));
            handleEditClose();
            showToast('User updated successfully', 'success');
        } catch (error) {
            console.error('Error updating user:', error);
            showToast('Error updating user. Please try again.', 'error');
        }
    };

    const handleNewSubmit = async (e) => {
        e.preventDefault();
        setNewFormSubmitted(true);

        if (!validateForm(newUser)) {
            showToast('Please fill all required fields.', 'error');
            setNewFormSubmitted(false);
            return;
        }

        try {
            const token = AuthProvider.getToken();
            const response = await axios.post(`${apiUrl}/create-user`, newUser, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setNewUser({
                username: '',
                email: '',
                password: '',
                role: ''
            });
            setNewFormSubmitted(false);
            showToast('New user created successfully', 'success');
            await fetchUsers();
            handleNewClose()// Refresh the user list
        } catch (error) {
            console.error('Error creating new user:', error);
            showToast('Error creating new user. Please try again.', 'error');
            setNewFormSubmitted(false);
        }
    };

    const handleDelete = async (userId) => {
        if (currentUser && currentUser.id === userId) {
            showToast("You can't delete yourself.", 'error');
            return;
        }

        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                const token = AuthProvider.getToken();
                await axios.delete(`${apiUrl}/delete/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                setUsers(users.filter(user => user.id !== userId));
                showToast('User deleted successfully', 'success');
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    showToast("You can't delete your own account.", 'error');
                } else {
                    console.error('Error deleting user:', error);
                    showToast('Error deleting user. Please try again.', 'error');
                }
            }
        }
    };

    const showToast = (message, type) => {
        toast(message, { type });
    };

    const getRoles = (roles) => {
        if (!roles || roles.length === 0) {
            return 'No role assigned'; // Default message for no roles
        }
        // Assuming roles is an array of objects with a 'name' property
        return roles.map(role => role.name).join(', ');
    };

    return (
        < div className="md:p-4">
            <div className="w-auto lg:py-2  pt-3 md:pt-0">
                <button variant="primary" onClick={handleNewOpen} className='max-w-32 mb-2 flex items-center gap-2 rounded-md bg-success text-white px-3 py-2 cursor-pointer'>
                    <div className='flex items-center justify-center gap-2 text-white'>
                        <FaPlus /> New User
                    </div>
                </button>
            </div>
            {loading ? (
                <Spinner animation="border" />
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : (
                <div className='bg-white p-2 max-w-3xl rounded-md shadow-md' style={{ overflowX: 'auto' }}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(users) && users.length > 0 ? (
                                users.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.username}</td>
                                        <td>{user.email}</td>
                                        <td>{getRoles(user.roles)}</td>
                                        <td className='flex flex-col lg:flex-row gap-2'>
                                            <Button className="btn btn-sm" variant="warning" onClick={() => handleEditOpen(user)}>
                                                <div className='flex items-center justify-center'>
                                                    <FaEdit />
                                                </div>
                                            </Button>{' '}
                                            <Button className="btn btn-sm" variant="danger" onClick={() => handleDelete(user.id)}>
                                                <div className='flex items-center justify-center'>
                                                    <FaTrash />
                                                </div>
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">No users available</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            )}

            {/* Edit Modal */}
            <Modal show={showEditModal} onHide={handleEditClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                value={selectedUser.username || ''}
                                onChange={handleEditChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={selectedUser.email || ''}
                                onChange={handleEditChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formRole">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                as="select"
                                name="role"
                                value={selectedUser.role || ''}
                                onChange={handleEditChange}
                            >
                                <option value="">Select role</option>
                                {roles.map((role) => (
                                    <option key={role.id} value={role.name}>{role.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Button variant="primary" onClick={handleEditSubmit}>
                            Save Changes
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* New User Modal */}
            <Modal show={showNewModal} onHide={handleNewClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleNewSubmit}>
                        <Form.Group controlId="formNewUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                value={newUser.username}
                                onChange={handleNewChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formNewEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={newUser.email}
                                onChange={handleNewChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formNewPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                value={newUser.password}
                                onChange={handleNewChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formNewRole">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                as="select"
                                name="role"
                                value={newUser.role}
                                onChange={handleNewChange}
                                required
                            >
                                <option value="">Select role</option>
                                {roles.map((role) => (
                                    <option key={role.id} value={role.name}>{role.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled={newFormSubmitted}>
                            {newFormSubmitted ? 'Saving...' : 'Save'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <ReactToastContainer />
        </div>
    );
};

export default UserList;
