import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Container, Row, Col, Table, Spinner, Alert, Button, Modal, Form
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import ENV from "../utils/Constant";
import AuthProvider from "../utils/AuthProvider";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { ArrowLeftCircle } from "react-bootstrap-icons";

const DesignationList = () => {
    const navigate = useNavigate();
    const { cadreId } = useParams();
    const apiUrl = ENV.baseUrl;
    const [designations, setDesignations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [AddCadreVerticalReservation, setAddCadreVerticalReservation] = useState(true);
    const [Count, setCount] = useState(0);

    const [editDesignation, setEditDesignation] = useState(null);
    const [newDesignation, setNewDesignation] = useState({
        designationName: '',
        totalDesignationSanctionedStrength: '',
        totalManInPositionOnThatDesignation: '',
        vacancy: '',
    });
    const [cadreTotalSanctionedStrength, setCadreTotalSanctionedStrength] = useState(0);
    // const [horizontalReservations, setHorizontalReservations] = useState([]);
    // const [verticalReservations, setVerticalReservations] = useState([]);
    const [designationID, setDesignationID] = useState();
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [selectedFieldId, setSelectedFieldId] = useState('');
    const [errors, seterrors] = useState('');
    const [totalDesignationSanctionedStrength, settotalDesignationSanctionedStrength] = useState('');
    const [additionalFields, setAdditionalFields] = useState([]);
    const [dropdownCategoryOptions, setdropdownCategoryOptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

                const response = await axios.get(`${apiUrl}/vertical-reservations`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                // setVerticalReservations(response.data);
                // console.log(response.data);
                setDropdownOptions(response.data);
                setLoading(false);
                setError(null);
            } catch (error) {
                console.error('Error fetching vertical reservations:', error);
                setError('Error fetching vertical reservations. Please try again.');
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);

    useEffect(() => {
        const fetchHorizontalReservations = async () => {
            setLoading(true);
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

                const response = await axios.get(`${apiUrl}/horizontal-reservations`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                // setHorizontalReservations(response.data);
                setdropdownCategoryOptions(response.data);
                // console.log(response.data);
                // setLoading(false);
                // setError(null);
            } catch (error) {
                console.error('Error fetching horizontal reservations:', error);
                setError('Error fetching horizontal reservations. Please try again.');
                setLoading(false);
            }
        };

        fetchHorizontalReservations();
    }, [apiUrl]);

    const handleBackClick = () => {
        navigate(-1); // Navigate back in history
    };

    const fetchDesignations = async () => {
        setLoading(true);
        try {
            const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

            const response = await axios.get(`${apiUrl}/designations/by-cadre/${cadreId}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                }
            });

            if (Array.isArray(response.data)) {
                console.log(response.data);
                settotalDesignationSanctionedStrength(response.data[0].totalDesignationSanctionedStrength)
                setDesignations(response.data);
                if (response.data.length > 0) {
                    setCadreTotalSanctionedStrength(response.data[0].cadre.totalCadreSanctionedStrength);
                }
            } else {
                console.error('API response is not an array:', response.data);
                setError('Unexpected response format.');
            }
        } catch (error) {
            console.error('Error fetching designations:', error);
            setError('Error fetching designations. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchDesignations();
    }, [apiUrl, cadreId]);

    const handleAddField = () => {
        setAdditionalFields([...additionalFields, { id: Count, hrcategoryId: '', cfValue: '' }]);
        setCount(Count + 1)
    };

    const handleFieldChange = (index, field, value) => {
        const updatedFields = [...additionalFields];
        updatedFields[index][field] = value;
        setAdditionalFields(updatedFields);

        if (field === 'hrcategoryId') {
            const updatedOptions = dropdownCategoryOptions.filter(option => option.categoryId !== value);
            setdropdownCategoryOptions(updatedOptions);
        }
    };

    const handleAddDesignation = async () => {
        const { designationName, totalDesignationSanctionedStrength, totalManInPositionOnThatDesignation, vacancy } = newDesignation;
        // console.log(designationName, totalDesignationSanctionedStrength, totalManInPositionOnThatDesignation, vacancy);

        if (!designationName || !totalDesignationSanctionedStrength || !totalManInPositionOnThatDesignation) {
            toast.error('Please fill in all fields.');
            return;
        }

        // Uncomment validation checks if needed
        // if (parseInt(totalDesignationSanctionedStrength) >= cadreTotalSanctionedStrength) {
        //     toast.error('Sanctioned Strength cannot exceed Total Cadre Sanctioned Strength.');
        //     return;
        // }

        // if (parseInt(totalManInPositionOnThatDesignation) > parseInt(totalDesignationSanctionedStrength)) {
        //     toast.error('Man in Position cannot exceed Sanctioned Strength.');
        //     return;
        // }

        try {
            const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider
            const response = await axios.post(`${apiUrl}/designations/create-designation`, {
                ...newDesignation,
                cadre: { cadreId }
            }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                }
            });
            setDesignationID(response.data.designationId)
            setAddCadreVerticalReservation(false);
            // setShowAddModal(false);
            setNewDesignation({
                designationName: '',
                totalDesignationSanctionedStrength: '',
                totalManInPositionOnThatDesignation: '',
                vacancy: '',
            });
            await fetchDesignations();
            toast.success('Designation added successfully!');
        } catch (error) {
            console.error('Error adding designation:', error.response ? error.response.data : error.message);
            toast.error('Error adding designation. Please try again.');
        }
    };

    const SaveDesignation = async () => {
        if (!designationID) {
            toast.error('Designation ID is required.');
            return;
        }

        if (!selectedFieldId) {
            toast.error('Vertical Reservation Category is required.');
            return;
        }

        if (additionalFields.length === 0) {
            toast.error('At least one horizontal reservation field is required.');
            return;
        }
        try {
            const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

            // Convert data format to [designationId, vrcategoryId, hrcategoryId, cfValue]
            const transformedData = additionalFields.map(field => ({
                designationId: parseInt(designationID, 10), // Ensure designationID is an integer
                vrcategoryId: parseInt(selectedFieldId, 10), // Ensure selectedFieldId is an integer
                hrcategoryId: parseInt(field.hrcategoryId, 10), // Convert hrcategoryId to integer
                cfValue: parseFloat(field.cfValue) // Convert cfValue to a number (float)
            }));

            // console.log(transformedData);

            const response = await axios.post(`${apiUrl}/carry-forward/create-carry-forward`, transformedData, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                }
            });

            setCount(0);
            setAddCadreVerticalReservation(true);
            setSelectedFieldId('');
            setAdditionalFields([]);
            await fetchDesignations();
            toast.success('Designation added successfully!');
        } catch (error) {
            console.error('Error adding designation:', error.response ? error.response.data : error.message);
            toast.error('Error adding designation. Please try again.');
        }
    }


    const SaveAndCreateDesignation = async () => {
        // console.log(selectedFieldId, 'selectedFieldId');
        // console.log('additionalFields', additionalFields);
        // console.log('designationID', designationID);
        // const requestData = {
        //     verticalReservationId: selectedFieldId,       // selectedFieldId
        //     additionalFields: additionalFields, // additionalFields array
        //     designationId: designationID    // designationID
        // };

        // console.log(requestData)
        if (!designationID) {
            toast.error('Designation ID is required.');
            return;
        }

        if (!selectedFieldId) {
            toast.error('Vertical Reservation Category is required.');
            return;
        }

        if (additionalFields.length === 0) {
            toast.error('At least one horizontal reservation field is required.');
            return;
        }
        try {
            const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

            const transformedData = additionalFields.map(field => ({
                designationId: parseInt(designationID, 10), // Ensure designationID is an integer
                vrcategoryId: parseInt(selectedFieldId, 10), // Ensure selectedFieldId is an integer
                hrcategoryId: parseInt(field.hrcategoryId, 10), // Convert hrcategoryId to integer
                cfValue: parseFloat(field.cfValue) // Convert cfValue to a number (float)
            }));

            const response = await axios.post(`${apiUrl}/carry-forward/create-carry-forward`, transformedData, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                }
            });

            setCount(0)
            setSelectedFieldId('')
            setAdditionalFields([])
            await fetchDesignations();
            toast.success('Designation added successfully!');
        } catch (error) {
            console.error('Error adding designation:', error.response ? error.response.data : error.message);
            toast.error('Error adding designation . Please try again.');
        }


    }

    const handleEditDesignation = async () => {
        const { designationName, totalDesignationSanctionedStrength, totalManInPositionOnThatDesignation, vacancy } = editDesignation;

        if (!designationName || !totalDesignationSanctionedStrength || !totalManInPositionOnThatDesignation || !vacancy) {
            toast.error('Please fill in all fields.');
            return;
        }

        if (parseInt(totalDesignationSanctionedStrength) > cadreTotalSanctionedStrength) {
            toast.error('Sanctioned Strength cannot exceed Total Cadre Sanctioned Strength.');
            return;
        }

        if (parseInt(totalManInPositionOnThatDesignation) > parseInt(totalDesignationSanctionedStrength)) {
            toast.error('Man in Position cannot exceed Sanctioned Strength.');
            return;
        }

        try {
            const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider
            await axios.put(`${apiUrl}/designations/edit/${editDesignation.designationId}`, editDesignation, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                }
            });

            setShowEditModal(false);
            setEditDesignation(null);
            await fetchDesignations();
            toast.success('Designation updated successfully!');
        } catch (error) {
            console.error('Error editing designation:', error.response ? error.response.data : error.message);
            toast.error('Error editing designation. Please try again.');
        }
    };

    const handleDeleteDesignation = async (id) => {
        try {
            const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

            await axios.delete(`${apiUrl}/designations/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                }
            });

            await fetchDesignations();
            toast.success('Designation deleted successfully!');
        } catch (error) {
            console.error('Error deleting designation:', error.response ? error.response.data : error.message);
            toast.error('Error deleting designation. Please try again.');
        }
    };

    const handleCalculateReservation = async (designationId) => {
        navigate(`/reservation/${designationId}`);
        // try {
        //     const response = await axios.get(`${apiUrl}/reservations/calculate-seat-reservation/${designationId}`);
        //     toast.success('Reservation calculated successfully!');
        //     console.log(response.data);
        // } catch (error) {
        //     console.error('Error calculating reservation:', error.response ? error.response.data : error.message);
        //     toast.error('Error calculating reservation. Please try again.');
        // }
    };
    const buttonStyle = {
        backgroundColor: '#007bff', // Blue background
        color: '#fff', // White text
        border: 'none', // Remove border
        padding: '10px 20px', // Padding
        borderRadius: '5px', // Rounded corners
        cursor: 'pointer', // Pointer cursor on hover
        display: 'flex', // Flexbox for alignment
        alignItems: 'center', // Center items vertically
        fontSize: '16px', // Font size
        marginBottom: '20px' // Space between button and table
    };

    // Inline styles for the icon
    const iconStyle = {
        marginRight: '8px', // Space between icon and text
    };

    const Userroles = localStorage.getItem('role')

    const handleSanctionedStrengthChange = (e) => {
        const value = e.target.value === '' ? '' : parseInt(e.target.value);
        // Update sanctioned strength

        if (value < totalDesignationSanctionedStrength) {
            setNewDesignation((prev) => ({
                ...prev,
                totalDesignationSanctionedStrength: value,
                vacancy: Math.max(0, value - prev.totalManInPositionOnThatDesignation)
            }));
        } else {
            seterrors(`Sanctioned Strength should be less than ${totalDesignationSanctionedStrength}`);
        }
        setTimeout(() => {
            seterrors('')
        }, 3000);
    };

    const handleManInPositionChange = (e) => {
        const value = e.target.value === '' ? '' : parseInt(e.target.value);
        const sanctionedStrength = parseInt(newDesignation.totalDesignationSanctionedStrength);
        // Update man in position
        if (value <= sanctionedStrength) {
            setNewDesignation((prev) => ({
                ...prev,
                totalManInPositionOnThatDesignation: value,
                vacancy: Math.max(0, sanctionedStrength - value)
            }));
        } else {
            seterrors(`Man in Position should be less than or equal to Sanctioned Strength ${sanctionedStrength}`);
        }

        setTimeout(() => {
            seterrors('')
        }, 3000);
    };

    const handleVacancyChange = (e) => {
        const value = e.target.value;
        // Ensure vacancy does not directly change; it is derived from sanctioned strength and man in position
        setNewDesignation((prev) => ({
            ...prev,
            // Keep the vacancy field read-only or calculate based on the other two fields
            vacancy: Math.max(0, prev.totalDesignationSanctionedStrength - prev.totalManInPositionOnThatDesignation) // Ensure non-negative value
        }));
    };
    const handleViewDesignations = (cadreId) => {
        navigate(`/carry-forward/${cadreId}`);
    }
    return (
        < div className="md:p-4">

            <div className='flex flex-col lg:flex-row w-auto pt-2 gap-2 items-center'>
                <button onClick={handleBackClick} style={buttonStyle}>
                    <ArrowLeftCircle style={iconStyle} /> Go Back
                </button>
                {Userroles === 'ROLE_ADMIN' && (
                    <button onClick={() => setShowAddModal(true)} style={buttonStyle}>
                        <div className='flex items-center gap-2 text-white'>
                            <FaPlus /> Add New Designation
                        </div>
                    </button>
                )}
            </div>
            {designations.length > 0 && (
                <div className='flex flex-col lg:flex-row w-auto py-2'>
                    <h2>{designations[0].cadre.cadreName}</h2>
                </div>
            )}

            {loading ? (
                <Spinner animation="border" />
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : (
                <div style={{ overflowX: 'auto' }}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th className='hidden lg:table-cell'>Designation</th>
                                <th className='hidden lg:table-cell'>Sanctioned Strength</th>
                                <th className='hidden lg:table-cell'>Man in Position</th>
                                <th>Vacancy</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {designations.length > 0 ? (
                                designations.map((designation) => (
                                    <tr key={designation.designationId}>
                                        <td className=''>{designation.designationName}</td>
                                        <td className=''>{designation.totalDesignationSanctionedStrength}</td>
                                        <td className=''>{designation.totalManInPositionOnThatDesignation}</td>
                                        <td>{designation.vacancy}</td>
                                        <td className='flex gap-2 flex-col lg:flex-row'>
                                            <div className='flex gap-2 flex-col lg:flex-row'>
                                                <Button variant="secondary"
                                                    onClick={() => handleViewDesignations(designation.designationId)}>
                                                    View Carry Forward
                                                </Button>
                                                <Button
                                                    variant="secondary"
                                                    onClick={() => handleCalculateReservation(designation.designationId)}
                                                >
                                                    Calculate Reservation
                                                </Button>
                                            </div>
                                            <div className='flex lg:w-auto w-full gap-2 '>
                                                <Button
                                                    className='flex items-center justify-center w-full'
                                                    variant="warning"
                                                    onClick={() => {
                                                        setEditDesignation(designation);
                                                        setShowEditModal(true);
                                                    }}
                                                >
                                                    <div className='flex items-center justify-center'>
                                                        <FaEdit />
                                                    </div>
                                                </Button> {''}
                                                <Button
                                                    className='flex items-center justify-center w-full'
                                                    variant="danger"
                                                    onClick={() => handleDeleteDesignation(designation.designationId)}
                                                >
                                                    <div className='flex items-center justify-center'>
                                                        <FaTrash />
                                                    </div>
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No designations found</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            )}

            {/* Add Designation Modal */}
            <Modal show={showAddModal} onHide={() => {
                setAddCadreVerticalReservation(true);
                setShowAddModal(false)
            }}>
                <Modal.Header closeButton>
                    <div className='flex flex-col'>
                        <Modal.Title>Add New Designation</Modal.Title>
                        {errors &&
                            <div className='flex items-center gap-2 text-red-500'>
                                {errors}
                            </div>}
                    </div>
                </Modal.Header>
                {AddCadreVerticalReservation ?
                    <>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="formDesignationName">
                                    <Form.Label>Designation Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter designation name"
                                        value={newDesignation.designationName}
                                        onChange={(e) => setNewDesignation({ ...newDesignation, designationName: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formSanctionedStrength">
                                    <Form.Label>Sanctioned Strength</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter sanctioned strength"
                                        value={newDesignation.totalDesignationSanctionedStrength}
                                        onChange={handleSanctionedStrengthChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formManInPosition">
                                    <Form.Label>Man in Position</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter man in position"
                                        value={newDesignation.totalManInPositionOnThatDesignation}
                                        onChange={handleManInPositionChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formVacancy">
                                    <Form.Label>Vacancy</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter vacancy"
                                        value={newDesignation.vacancy}
                                        onChange={handleVacancyChange}
                                        readOnly
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowAddModal(false)}>
                                Close
                            </Button>
                            <Button variant="success" onClick={handleAddDesignation}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </>
                    :
                    <>
                        <Modal.Body>
                            <Form>
                                {/* Dropdown for Additional Fields */}
                                <Form.Group className="mb-3" controlId="formAdditionalFields">
                                    <Form.Label>Select Category</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={selectedFieldId}
                                        onChange={(e) => setSelectedFieldId(e.target.value)}
                                    >
                                        <option value="">Select an option</option>
                                        {dropdownOptions.map((option) => (
                                            <option key={option.categoryId} value={option.categoryId}>
                                                {option.categoryName}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {/* Additional Fields */}
                                {additionalFields.map((field, index) => (
                                    <div key={index} className="mb-3 flex gap-2 w-full">
                                        <Form.Group className="mb-3 w-9/12" controlId={`formAdditionalFieldCategory${index}`}>
                                            <Form.Label>Category Name</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={field.name}
                                                onChange={(e) => handleFieldChange(index, 'hrcategoryId', e.target.value)}
                                            >
                                                <option value="">Select an option</option>
                                                {dropdownCategoryOptions.map((option) => (
                                                    <option key={option.categoryId} value={option.categoryId}>
                                                        {option.categoryName}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3 w-1/4" controlId="formAdditionalFields">
                                            <Form.Label>Value</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Value"
                                                value={field.value}
                                                onChange={(e) => handleFieldChange(index, 'cfValue', e.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                ))}
                                <Button variant="outline-primary" onClick={handleAddField}>
                                    Add Field
                                </Button>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {
                                setAddCadreVerticalReservation(true);
                                setShowAddModal(false)
                            }}>
                                Close
                            </Button>
                            <Button variant="success" onClick={SaveDesignation}>
                                Save
                            </Button>
                            <Button variant="success" onClick={SaveAndCreateDesignation}>
                                Save and Create another
                            </Button>
                        </Modal.Footer>
                    </>
                }
            </Modal >


            {/* Edit Designation Modal */}
            < Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Designation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editDesignation && (
                        <Form>
                            <Form.Group className="mb-3" controlId="formEditDesignationName">
                                <Form.Label>Designation Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter designation name"
                                    value={editDesignation.designationName}
                                    onChange={(e) => setEditDesignation({
                                        ...editDesignation,
                                        designationName: e.target.value
                                    })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEditSanctionedStrength">
                                <Form.Label>Sanctioned Strength</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter sanctioned strength"
                                    value={editDesignation.totalDesignationSanctionedStrength}
                                    onChange={(e) => setEditDesignation({
                                        ...editDesignation,
                                        totalDesignationSanctionedStrength: e.target.value
                                    })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEditManInPosition">
                                <Form.Label>Man in Position</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter man in position"
                                    value={editDesignation.totalManInPositionOnThatDesignation}
                                    onChange={(e) => setEditDesignation({
                                        ...editDesignation,
                                        totalManInPositionOnThatDesignation: e.target.value
                                    })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEditVacancy">
                                <Form.Label>Vacancy</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter vacancy"
                                    value={editDesignation.vacancy}
                                    onChange={(e) => setEditDesignation({ ...editDesignation, vacancy: e.target.value })}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Close
                    </Button>
                    <Button variant="success" onClick={handleEditDesignation}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal >

            <ToastContainer />
        </div>
    );
};

export default DesignationList;
