import React, { useEffect, useState } from 'react';
import AuthProvider from '../utils/AuthProvider';
import axios from 'axios';
import ENV from '../utils/Constant';
import { useNavigate } from 'react-router-dom';

const CadreCalculations = () => {
    const [selectedCadre, setSelectedCadre] = useState('');
    const [selectedDesignation, setSelectedDesignation] = useState('');
    const [vacancy, setVacancy] = useState(null);
    const [carryForwardsCount, setCarryForwardsCount] = useState(null);
    const [totalDesignationSanctionedStrength, settotalDesignationSanctionedStrength] = useState(null);
    const apiUrl = ENV.baseUrl;
    const [loading, setLoading] = useState(false);
    const [DisableButton, setDisableButton] = useState(true);
    const [error, setError] = useState(null);
    const [cadreResponse, setcadreResponse] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = AuthProvider.getToken(); // Retrieve the token using AuthProvider

                const response = await axios.get(`${apiUrl}/cadres/cadre-with-designations-and-carry-forward`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the JWT token in the Authorization header
                    }
                });

                // console.log("response", response.data);
                setcadreResponse(response.data)
                setError(null);
            } catch (error) {
                console.error('Error fetching vertical reservations:', error);
                setError('Error fetching vertical reservations. Please try again.');
            }
        };

        fetchData();
    }, [apiUrl]);

    // Handle cadre selection
    const handleCadreChange = (e) => {
        const selected = e.target.value;
        setSelectedCadre(selected);
        setSelectedDesignation(''); // Reset designation when cadre changes
        setVacancy(null);
        setCarryForwardsCount(null);
        setDisableButton(true)

    };

    // Handle designation selection
    const handleDesignationChange = (e) => {
        const designationId = e.target.value;
        const cadre = cadreResponse.find((cadre) => cadre.cadreId === parseInt(selectedCadre));
        const designation = cadre?.designations.find((des) => des.designationId === parseInt(designationId));
        let totalCount = 0;
        setDisableButton(false)

        designation.carryForwards?.forEach(carryForward => {
            totalCount += carryForward.cfValue;
        });
        console.log(designation);

        setSelectedDesignation(designationId);
        setVacancy(designation?.vacancy);
        setCarryForwardsCount(totalCount);
        settotalDesignationSanctionedStrength(designation?.totalDesignationSanctionedStrength)
    };

    const handleViewDesignations = (e) => {
        e.preventDefault()
        setLoading(true)

        setTimeout(() => {
            navigate(`/reservation-calculations/${selectedDesignation}`);
            setLoading(false)
        }, 1000);
    };

    return (
        <div className='md:p-4 w-full '>
            <div className='max-w-xl '>
                <h2 className='text-xl pt-2 md:pt-0'>Reservation Calculations</h2>

                <form onSubmit={handleViewDesignations} className='flex flex-col p-4 bg-white shadow-md rounded-md max-w-xl mx-auto  gap-4'>
                    {/* Cadre Selection */}
                    <div className='flex flex-col gap-2'>
                        <label htmlFor="cadre" className='text-lg font-bold'>Select Cadre: </label>
                        <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            id="cadre" value={selectedCadre} onChange={handleCadreChange}>
                            <option value="">Select Cadre</option>
                            {cadreResponse.map((cadre) => (
                                <option key={cadre.cadreId} value={cadre.cadreId}>
                                    {cadre.cadreName}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Designation Selection */}
                    <div>
                        <label htmlFor="designation" className='text-lg font-bold'>Select Designation: </label>
                        <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            id="designation"
                            value={selectedDesignation}
                            onChange={handleDesignationChange}
                            disabled={!selectedCadre} // Disable until cadre is selected
                        >
                            <option value="">Select Designation</option>
                            {selectedCadre &&
                                cadreResponse
                                    .find((cadre) => cadre.cadreId === parseInt(selectedCadre))
                                    ?.designations.map((designation) => (
                                        <option key={designation.designationId} value={designation.designationId}>
                                            {designation.designationName}
                                        </option>
                                    ))}
                        </select>
                    </div>

                    {/* Display Vacancy and Carry Forward */}
                    {vacancy !== null && (
                        <div>
                            <p><strong>Actual Vacancy:</strong> {vacancy}</p>
                            <p><strong>Carry Forwards:</strong> {carryForwardsCount}</p>
                            <p><strong>Sanctioned Strength:</strong> {totalDesignationSanctionedStrength}</p>
                        </div>
                    )}

                    {/* Calculate Button */}
                    <button disabled={DisableButton} className='disabled:bg-gray-400 disabled:text-gray-100 btn btn-success py-2 rounded-md' type="submit">
                        {loading ?
                            <div className='flex items-center w-full justify-center'>
                                <div className="custom-loader"></div>
                            </div>
                            :
                            'Calculate'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CadreCalculations;
